const SERVICE_CODE = {
  OMNI: 'omni',
};

const PAGE_CODE = {
  CATALOG: {
    PRODUCT: 'product',
    BRAND: 'brand',
  },

  PPM: {
    PROMOTION_BUDGET: 'promotion-budget',
    VOUCHER: 'voucher',
    COUPON_GROUP: 'coupon-group',
    FLASHSALE: 'flashsale',
    PROMOTION: 'promotion',
    PROMOTION_CAMPAIGN: 'promotion-campaign',
    SHIPPING_FEE_PROMOTION: 'shipping-fee-promotion',
    COMBO_PROMOTION: 'combo-promotion',
    QR_PROMOTION: 'qr-promotion',
    COUPON: 'coupon',
  },

  PAGE_BUILDER: {
    PB: 'pb',
  },

  SUPPLY_CHAIN: {
    SC: 'sc',
  },

  ORDER_FULFILLMENT: {
    ORDER: 'order',
  },

  WMS: {
    WMS: 'wms',
  },

  CS: {
    CS: 'cs',
  },

  AS: {
    AS: 'as',
  },

  SETTINGS: {
    SHIPPING_POLICY: 'shipping-policy',
    COD_SERVICE: 'cod-service',
    POLICY: 'policy',
    SETTINGS: 'settings',
    SHIPPING_POLICY_v2: 'shipping-policy-v2',
    ADDITIONAL_POLICY_v2: 'additional-policy-v2',
    HIGHLIGHT: 'highlight',
    TERMINAL_SPOS: 'terminal-spos',
  },

  ACCOUNTING: {
    ACCOUNTING: 'accounting',
    ACCOUNTING_CASHIER: 'accounting-cashier',
  },

  CASHIER: {
    ACCOUNTING_CASHIER: 'accounting-cashier',
  },

  STAFF_MANAGEMENT: {
    STAFF: 'staff',
  },

  BIZ_GROUP: {
    BIZ_GROUP: 'biz-group',
  },

  SEO: {
    SEO: 'seo',
  },

  LOYALTY: {
    LOYALTY: 'loyalty',
  },
};

const USER_GUIDE_CODE = {
  CATALOG: {
    PRODUCT_SEARCH: 'product-search',
    CREATE_PRODUCT: 'create-product',
    VIEW_EDIT_PRODUCT: 'view-edit-product',
    IMPORT_PRODUCT: 'import-product',
    MANAGE_CATEGORY: 'manage-category',
    NORMAL_PRICE: 'normal-price',
    CREATE: 'create',
  },

  PPM: {
    CREATE: 'create',
    TRACKING: 'tracking',
  },

  PAGE_BUILDER: {
    CREATE_MENU: 'create-menu',
    CREATE_URL: 'create-url',
    CREATE_BANNER_TYPE: 'create-banner-type',
    CREATE_BANNER: 'create-banner',
    CREATE_MARKETING_CAMPAIGN: 'create-marketing-campaign',
    CREATE_PAGE: 'create-page',
    CREATE_NEWS: 'create-news',
    CREATE_BLOCK: 'create-block',
  },

  SUPPLY_CHAIN: {
    SUPPLIER_MANAGEMENT: 'supplier-management',
    SUPPLIER_PRICE: 'supplier-price',
    SUPPLY_STOCK_PLAN: 'supply-stock-plan',
    PURCHASE_ORDER: 'purchase-order',
    STOCK_REQUEST: 'stock-request',
    CREATE_STOCK_TRANSFER: 'create-stock-transfer',
    SUPPLIER_RETURN_REQUEST: 'supplier-return-request',
    BACK_ORDER: 'back-order',
    GOOD_COMMING_TRACKING: 'good-comming-tracking',
    INVENTORY_TRACKING: 'inventory-tracking',
    REBATE_PROGRAM_LIST: 'rebate-program-list',
    REBATE_CREATE_PROGRAM: 'rebate-create-program',
    STOCK_ALERT: 'stock-alert',
    ONLINE_OFFLINE_IMBALANCE_STOCK_ALERT:
      'online-offline-imbalance-stock-alert',
    CLEARANCE_TYPE: 'clearance-type',
    ROUTE_CONFIG: 'route-config',
    REQUEST_SHIPPING: 'request-shipping',
  },

  ORDER_FULFILLMENT: {
    ALL_ORDERS: 'all-orders',
    FULFILLED_BY_SELLER: 'fulfilled-by-seller',
    RETURN_REQUESTS: 'return-requests',
    TICKET_LIST: 'ticket-list',
    IMPORT_SHIPMENTS_STATUS: 'import-shipments-status',
  },

  WMS: {
    SITE: 'site',
    WAREHOUSE: 'warehouse',
    BIN_LOCATION: 'bin-location',
    PICKINGLIST_MANAGE: 'pickinglist-manage',
    PICKINGLIST_CREATE: 'pickinglist-create',
    PACK_CREATE: 'pack-create',
    DELIVERY_CREATE: 'delivery-create',
    WMSTRANSACTION_MANAGE: 'wmstransaction-manage',
    STOCK_ADJUST_RECEIPT: 'stock-adjust-receipt',
    STOCK_ADJUST_ISSUE: 'stock-adjust-issue',
  },

  CS: {
    PURCHASEINVOICE_CREATE: 'Purchaseinvoice-create',
  },

  AS: {
    CASHIER_CONFIRM_ORDER: 'cashier-confirm-order',
    CASHIER_CREATE_RECEIPT: 'cashier-create-receipt',
    CASHIER_OTHER_PAYMENT_IN: 'cashier-other-payment-in',
    CASHIER_PAYOO_COLLECTION: 'cashier-payoo-collection',
    CASHIER_CREATE_REFUND: 'cashier-create-refund',
    CASHIER_END_SHIFT: 'cashier-end-shift',
    ACC_IMPORT_TRANSACTION: 'acc-import-transaction',
    ACC_BANK_ACCOUNT: 'acc-bank-account',
    ACC_BANK_STATEMENTS: 'acc-bank-statements',
    ACC_PENDING_BANK_STATEMENTS: 'acc-pending-bank-statements',
    ACC_CLOSE_THE_BOOKS: 'acc-close-the-books',
  },

  ACCOUNTING: {
    CUSTOMER_CREDIT_LIMITS: 'customer-credit-limits',
    REFUND_REQUESTS: 'refund-requests',
    END_OF_SHIFT_REPORT: 'end-of-shift-report',
    CASH_BALANCE_REPORT: 'cash-balance-report',
  },

  SETTINGS: {
    CREATE: 'create',
    DISCOUNT_APPROVAL: 'discount-approval',
    LOYALTY_PROGRAM: 'loyalty-program',
  },

  CASHIER: {
    REFUND_REQUESTS: 'refund-requests',
    CREATE_PAYMENT_RECEIPT_IN: 'create-payment-receipt-in',
    CREATE_PAYMENT_RECEIPT_OUT: 'create-payment-receipt-out',
    LIST_PAYMENT_RECEIPT_OUT: 'list-payment-receipt',
  },

  STAFF_MANAGEMENT: {
    CREATE: 'create',
  },

  BIZ_GROUP: {
    CREATE: 'create',
  },

  SEO: {
    REDIRECT_MANAGEMENT: 'redirect-management',
  },

  // Below are the user guide codes that don't have page code
  CUSTOMER_MANAGEMENT: 'customer-management',

  LOYALTY: {
    LIST_CAMPAIGN: 'list-campaign',
    REPORT_OVERVIEW: 'report-overview',
  },
};

export default {
  SERVICE_CODE,
  PAGE_CODE,
  USER_GUIDE_CODE,
};
