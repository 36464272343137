import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/es/modal';
import { t } from 'helpers/i18n';

const showConfirmModal = (props: ModalFuncProps) => {
  Modal.confirm({
    title: t('RequestConfirmation'),
    maskClosable: true,
    content: t('AreYouSureToContinue'),
    okText: t('Confirm'),
    cancelText: t('Cancel'),
    ...props,
  });
};

export default {
  showConfirmModal,
};
