import { notification } from 'antd';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { t } from 'helpers/i18n';
import { AxiosRequestCustomConfig } from 'interfaces';
import { userServices } from 'services';

const handleResponseError = (
  error: AxiosError & {
    config: AxiosRequestConfig & AxiosRequestCustomConfig;
  }
) => {
  const status = error && error.response && error.response.status;
  switch (status) {
    case 401:
      userServices.logout();
      break;
    case 403:
      userServices.denyAccess();
      break;
    default:
      if (
        error.config?.isBypassNormalErrorRequests &&
        !isNotFoundErrorFromIAMService(error)
      ) {
        break;
      }
      let message = null;
      // Handle error message from API response
      if (error.response && error.response.data) {
        const { data } = error.response;
        message = data.message;
      }
      notification.error({
        message: t('Error'),
        description: message || t('SomethingWentWrong'),
      });
      break;
  }
};

const isNotFoundErrorFromIAMService = (error: AxiosError) => {
  return /^404[0-9]{2}$/.test(error.response?.data?.error?.code?.toString());
};

export const getResult = (response: AxiosResponse) => response.data.result;
export default {
  handleResponseError,
};
