import { useState, useEffect, useContext, useRef } from 'react';
import { IUserDetailsInfo } from 'teko-oauth2';
import userHelpers from 'helpers/user';
import { userServices, staffServices } from 'services';
import { IRoute, ISite, ManualTrackingScreenName } from 'interfaces';
import { StoreContext } from 'contexts';
import routes from 'routes';
import { matchPath, useLocation } from 'react-router-dom';
import { browserHistory } from 'helpers';
import { NetworkContext } from 'contexts/network';
import { useTrackPageView } from 'react-tracker-teko';
import { flagConstants } from 'constants/index';
import { EvaluatedFlags } from 'flagsup-ts';

const { getCurrentSite, setCurrentSite } = userHelpers;

const useUserInfo = () => {
  const [currentUser, setCurrentUser] = useState<IUserDetailsInfo>();
  const { isOnlineMode } = useContext(NetworkContext);

  const getFullUserInfo = async () => {
    const fullUserInfo = await userServices.getFullUserInfo(isOnlineMode);
    setCurrentUser(fullUserInfo);
  };

  useEffect(() => {
    getFullUserInfo();
  }, []);

  return { currentUser };
};

const useAuthorizationData = (items: IRoute[]) => {
  const { currentUser, featureFlagsData, websites } = useContext(StoreContext);
  const { isOnlineMode } = useContext(NetworkContext);

  // Get navigation which match permissions to build menu
  const filteredNavigation = userHelpers.filterHasPermissions(
    items,
    currentUser,
    featureFlagsData,
    websites,
    isOnlineMode
  );

  // Only get routes which is link to a component
  const filteredRoutes = filteredNavigation.filter(
    item => !item.children && item.component
  );

  return {
    filteredNavigation,
    filteredRoutes,
  };
};

const useUserSites = (
  currentUser?: IUserDetailsInfo,
  featureFlagsData?: EvaluatedFlags
) => {
  const [userSites, setUserSites] = useState<ISite[]>([]);
  const [fetchingUserSites, setFetchingUserSites] = useState(false);
  const currentSite = getCurrentSite();
  const [siteInfo, setSiteInfo] = useState<ISite>();
  const refHasFetchedUserSites = useRef(false);
  const location = useLocation();
  const { callTrackLoadPage, callTrackUnLoadPage } = useTrackPageView();
  const refPrevScreenName = useRef<ManualTrackingScreenName>();

  const enabledFlag =
    featureFlagsData?.[flagConstants.FLAG_KEYS.ALLOW_MULTIPLE_SITES]?.enabled;

  useEffect(() => {
    handleSetSiteInfoAfterFetching();
  }, [currentSite, userSites]);

  const getAllSites = async () => {
    try {
      setFetchingUserSites(true);
      const { sites } = await staffServices.getUserSites();
      setUserSites(sites);
      refHasFetchedUserSites.current = true;
    } finally {
      setFetchingUserSites(false);
    }
  };

  const handleSetSiteInfoAfterFetching = () => {
    if (!userSites.length) return;
    const cachedSite = userSites.find(
      site => site.id === parseInt(currentSite)
    );

    if (!cachedSite) {
      setCurrentSite(userSites[0].id);
    }

    setSiteInfo(cachedSite || userSites[0]);
  };

  const handleFetchSites = () => {
    if (!enabledFlag) return;
    const currentRoute = routes.find(route =>
      matchPath(window.location.pathname, {
        path: route.path,
        exact: true,
      })
    );
    // Set showSiteSwitch to true if the feature flag for showSiteSwitchWhenEnabledFlagKey is enabled
    if (
      !!currentRoute?.showSiteSwitchWhenEnabledFlagKey &&
      featureFlagsData?.[currentRoute.showSiteSwitchWhenEnabledFlagKey]?.enabled
    ) {
      currentRoute.showSiteSwitch = true;
    }

    // remove siteInfo if currentRoute doesn't use GSS
    if (!currentRoute?.showSiteSwitch) {
      setSiteInfo(undefined);
      return;
    }

    // fetch user sites if not having done
    if (!refHasFetchedUserSites.current) {
      getAllSites();
    }
    // set siteInfo again to pass to iframe
    else if (!siteInfo) {
      handleSetSiteInfoAfterFetching();
    }
  };

  // use for navigating in child app:
  // Check if showSiteSwitch of currentRoute is diff from location route of react's one, push current location to react router
  // (for rerendering correct container iframe src)
  useEffect(() => {
    if (!enabledFlag) return;
    const locationRoute = routes.find(route =>
      matchPath(location.pathname, {
        path: route.path,
        exact: true,
      })
    );
    if (locationRoute?.manualTrackingScreenName) {
      refPrevScreenName.current = locationRoute.manualTrackingScreenName;
      callTrackLoadPage({ screenName: locationRoute.manualTrackingScreenName });
    }

    // Handle detect screen view again & push location if having diff showSiteSwitch
    const handleSwitchRouteInChildApp = () => {
      if (refPrevScreenName.current) {
        callTrackUnLoadPage({
          screenName: locationRoute?.manualTrackingScreenName,
        });
      }

      const currentRoute = routes.find(route =>
        matchPath(window.location.pathname, {
          path: route.path,
          exact: true,
        })
      );

      if (!!currentRoute?.showSiteSwitch !== !!locationRoute?.showSiteSwitch) {
        browserHistory.push(window.location.pathname + window.location.search);
      }
      if (currentRoute?.manualTrackingScreenName) {
        refPrevScreenName.current = currentRoute.manualTrackingScreenName;
        callTrackLoadPage({
          screenName: currentRoute.manualTrackingScreenName,
        });
      }
    };
    window.addEventListener('popstate', handleSwitchRouteInChildApp);
    return () => {
      window.removeEventListener('popstate', handleSwitchRouteInChildApp);
      callTrackUnLoadPage({ screenName: refPrevScreenName.current });
    };
  }, [enabledFlag, location]);

  // use for navigating in parent app
  useEffect(() => {
    if (!currentUser?.meta_data?.siteIds?.length || !enabledFlag) return;

    handleFetchSites();
  }, [enabledFlag, currentUser, location]);

  return {
    fetchingUserSites,
    userSites,
    siteInfo,
  };
};

export default {
  useUserInfo,
  useAuthorizationData,
  useUserSites,
};
