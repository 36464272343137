import { trackAPICallTime } from 'helpers/common';

import {
  IGetAllPlatformsResponse,
  IGetAllSellersParams,
  IGetAllSellersResponse,
} from 'interfaces';
import { requestServices } from 'services';
import { getResult } from 'helpers/request';

const { sellerClient } = requestServices;

const getAllPlatforms = async (): Promise<IGetAllPlatformsResponse['platforms']> => {
  const MAX_LOOP_COUNT = 10;
  const MAX_PAGE_SIZE = 200;

  let currentPage = 1;
  let allPlatforms: IGetAllPlatformsResponse['platforms'] = [];

  let loopCount = 0; // hardcode to prevent loop too many times

  while (true) {
    if (loopCount >= MAX_LOOP_COUNT) {
      break;
    }

    const response = await trackAPICallTime(
      sellerClient.get('/platforms', {
        params: {
          pageSize: MAX_PAGE_SIZE,
          page: currentPage,
        },
      }),
      '/platforms'
    );

    const result = getResult(response);

    allPlatforms = allPlatforms.concat(result.platforms);

    if (result.platforms.length < MAX_PAGE_SIZE) {
      break;
    }

    currentPage++;
    loopCount++;
  }

  return allPlatforms;
};

const getAllSellers = (
  params: IGetAllSellersParams
): Promise<IGetAllSellersResponse> => {
  return sellerClient
    .get('/sellers/get-all', {
      params,
    })
    .then(getResult);
};

export default {
  getAllPlatforms,
  getAllSellers,
};
