import { Locale } from 'antd/es/locale-provider';
import { UpgradeTimeRange } from 'global';
import React from 'react';
import { Guide } from 'teko-help-center';

export interface GuideProps extends React.ComponentProps<typeof Guide> {}

export interface IPermission {
  app: string;
  resource: string;
  action?: string;
}

export interface IRoute {
  exact?: boolean;
  path: string;
  name: string;
  title?: string;
  component?: React.ElementType;
  /**
   * user need to have at least one of these permissions to access this route
   */
  permissions?: IPermission[];
  /**
   * check both role and permission
   */
  usePermissionPrefix?: boolean;
  icon?: React.ComponentType<{ className?: string }>;
  children?: string[];
  iFrameSrc?: string;
  redirectOutside?: boolean;
  needAppendSellerId?: boolean;
  needAppendUserId?: boolean;
  flagKey?: string;
  // DO NOT USE THIS 'hideWhenEnabledFlagKey' WITH 'flagKey' PROPERTIES. They're reverse and your route will never show up
  hideWhenEnabledFlagKey?: string;
  hideBreadcrumb?: boolean;
  hideTitle?: boolean;
  hideBackButton?: boolean;
  hidePlatformSwitch?: boolean;
  hideSellerSwitch?: boolean;
  hidePlatformSwitchByEpic?: boolean;
  isModalItem?: boolean;
  guide?: GuideProps;
  isUseMobileView?: boolean;
  // DO NOT USE THIS 'isNotUseMobileViewWhenEnabledFlagKey' WITH 'isUseMobileView' PROPERTIES. They're reverse
  isNotUseMobileViewWhenEnabledFlagKey?: string;
  survey?: ISurvey;
  isCustomWebsiteItem?: boolean;
  showSiteSwitch?: boolean;
  // USE THIS WITH showSiteSwitch to disabled site switch when flag key is enabled
  hideSiteSwitchWhenEnabledFlagKey?: string;
  showSiteSwitchWhenEnabledFlagKey?: string;
  supportOfflineMode?: boolean;
  manualTrackingScreenName?: ManualTrackingScreenName;
  /**
   * force to use platform config by current seller, ignore platformId from IAM.
   */
  isUsePlatformConfigBySeller?: boolean;
}

export enum ManualTrackingScreenName {
  WH_MANAGE_BIN = 'ManageBin',
  STOCK_IMPORT_REQUEST_LIST = 'StockImportRequestList',
  STOCK_IMPORT_REQUEST_DETAIL = 'StockImportRequestDetail',
  EXPORT_PROGRESS = 'WarehouseExportProgress',
  PICKING_LIST_MANAGEMENT = 'WarehousePickingListManagement',
  CREATE_PICKING_LIST = 'WarehouseCreatePickingList',
  EXPORT_REQUEST_HANDLING = 'ExportRequestHandling',
  HANDOVER = 'WarehouseHandover',
  HANDOVER_POR = 'WarehouseHandoverPOR',
  IMPORT_STOCK_BALANCE = 'ImportStockBalance',
  CREATE_STOCK_COUNT_SESSION = 'CreateStockCountSession',
  STOCK_COUNT_CYCLES = 'StockCountCycles',
}

export interface IRegionItem {
  key: string;
  name: string;
  flag: string;
  antdLocale: Locale;
}

export interface IRegion {
  [key: string]: IRegionItem;
}

export interface IFlagItem {
  enabled: boolean;
  expBranchId?: number;
  expId?: number;
  treatment?: string;
}

export interface IFlagsData {
  [key: string]: IFlagItem;
}

export interface ISurvey {
  surveyLink: string;
  surveyText: string;
}

export interface IClientConfig {
  code?: string;
  error?: any;

  appName: string;
  appLogo: string;
  appFavicon: string;
  iam: {
    clientId: string;
    oauthDomain: string;
  };
  trackerAppId: string;
  swRegistered: boolean;
}

export interface ICommonConfig {
  env: string;
  code?: string;
  error?: any;

  shouldMonitorSessionChangeBySessionId?: boolean;

  trackerJsFile?: string;
  baseTrackerUrl?: string;
  baseErpUrl?: string;
  baseSellerCenterV1Url?: string;
  baseSellerCenterV2Url?: string;
  baseSellerCenterTic41Url?: string;
  basePpmWebUrl?: string;
  baseWmsUrl?: string;
  baseSrmUrl?: string;
  basePosUrl?: string;
  baseFmsUrl?: string;
  baseNewWmsUrl?: string;
  baseLogisticsUrl?: string;
  baseAsUrl?: string;
  baseDataStudioUrl?: string;
  baseDposUrl?: string;
  baseSposUrl?: string;
  baseNotificationUrl?: string;
  baseUnsUrl?: string;
  baseLoyaltyServiceUrl?: string;
  baseHelpCenterUrl?: string;
  baseTicketManagementUrl?: string;
  baseCRMUrl?: string;
  baseRebateUrl?: string;
  baseUserSegmentUrl?: string;
  baseSmartBannerUrl?: string;
  baseCampaignManagementUrl?: string;
  baseMerchantMarketplaceUrl?: string;
  baseAuditLogUrl?: string;
  baseSupplyChainUrl?: string;
  baseOscmUrl?: string;
  baseSupersetUrl?: string;
  baseWebsiteEditorUrl?: string;
  baseNativeAppEditorUrl?: string;
  baseFinanceUrl?: string;
  baseLoyaltyManagementUrl?: string;

  apiSellerUrl: string;
  apiFlagsupUrl: string;
  apiLogisticsUrl?: string;
  apiMerchantBffUrl?: string;
  apiStaffBffUrl?: string;

  upgradeTimeRange?: UpgradeTimeRange;
  newComboMenuAllowSellers?: number[];
}

export interface AxiosRequestCustomConfig {
  /**
   * just ignore normal error from BFF, not including IAM, 401 and 403.
   */
  isBypassNormalErrorRequests?: boolean;
}
