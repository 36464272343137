import { createContext } from 'react';
import { IUserDetailsInfo } from 'teko-oauth2';
import { currentUser } from 'services/mocks/user';
import { sellerInfo, platformInfo } from 'services/mocks/seller';
import { IFlagsData, IPlatform, ISeller, ISite, Website } from 'interfaces';

interface StoreContextType {
  currentUser: IUserDetailsInfo;
  appName: string;
  authorizedPlatforms: IPlatform[];
  platformInfo: IPlatform | undefined;
  authorizedSellers: ISeller[];
  sellerInfo: ISeller;
  featureFlagsData: IFlagsData;
  websites: Website[];
  userSites: ISite[];
  platformConfigBySeller?: IPlatform;
  siteInfo?: ISite;
  fetchingContent?: boolean;
}

export const StoreContext = createContext<StoreContextType>({
  currentUser,
  appName: 'Staff Desktop',
  authorizedPlatforms: [platformInfo],
  platformInfo,
  platformConfigBySeller: undefined,
  authorizedSellers: [sellerInfo],
  sellerInfo,
  featureFlagsData: {},
  websites: [],
  userSites: [],
  fetchingContent: false,
});
