import { IUserDetailsInfo } from 'teko-oauth2';
import _ from 'lodash';
import localStorageConstants from 'constants/localStorage';
import { getSrc } from './url';
import permissionPrefixHelpers from './permissionPrefix';
import permissionExactHelpers from './permissionExact';
import permissionBIReportHelpers from './permissionBIReport';
import { userServices } from 'services';
import { IFlagsData, IRoute, ISeller, ISite, Website } from 'interfaces';
import {
  outsidePathConstants,
  appConstants,
  resourceConstants,
  flagConstants,
  actionConstants,
} from 'constants/index';
import stringHelpers from 'helpers/string';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { normalizeLowerCase } = stringHelpers;
const { baseDataStudioUrl } = getCommonConfig();

const {
  CURRENT_PLATFORM,
  CURRENT_SELLER,
  CURRENT_SITE,
} = localStorageConstants;

export const getPermissionCode = (
  app: string,
  resource: string,
  action?: string
) => {
  return [app, resource, action].filter(x => !!x).join(':');
};

const filterHasPermissions = (
  items: IRoute[],
  currentUser: IUserDetailsInfo,
  flagsData: IFlagsData,
  websites: Website[],
  isOnlineMode: boolean
) => {
  //Get level 1 items = top level menus
  const lvl1Items = items.filter(x => x.icon);
  //Get orphan items: neither child of any nor top-level route, usually dynamic route. Example: /orders/marketplace/:id
  const allChilrenPaths = items
    .filter(x => x.children && x.children.length > 0)
    .map(x => x.children)
    .flat();
  const orphanItems = items.filter(item => {
    const isChildOfAny = allChilrenPaths.includes(item.path);
    return !item.icon && !isChildOfAny;
  });
  return [...lvl1Items, ...orphanItems]
    .map(x =>
      getVisibleRoutes(x, items, currentUser, flagsData, websites, isOnlineMode)
    )
    .flat();
};

const getTempiWebsiteId = (websites: Website[]): number | null => {
  return (
    websites.find(
      website => website.tempiWebsiteId !== null && website.type === 'WEB'
    )?.tempiWebsiteId || null
  );
};

export const getTempiAppId = (websites: Website[]): number | null => {
  return (
    websites.find(
      website => website.tempiWebsiteId !== null && website.type === 'APP'
    )?.tempiWebsiteId || null
  );
};

const getCustomWebsitePath = (tempiWebsiteId: number) => {
  return outsidePathConstants.CUSTOM_WEBSITE_UI_PATH.replace(
    ':websiteId',
    tempiWebsiteId.toString()
  );
};

const getCustomMobileAppPath = (tempiWebsiteId: number) => {
  return outsidePathConstants.CUSTOM_MOBILE_APP_UI_PATH.replace(
    ':appId',
    tempiWebsiteId.toString()
  );
};

/* 
  Given a flat menu tree & current user info, return list of visible/accessible routes of a node
  Note:
  - Only check permission for leaf route
  - Parent route visibility will depends on its chilren
*/
const getVisibleRoutes: (
  node: IRoute,
  items: IRoute[],
  currentUser: IUserDetailsInfo,
  flagsData: IFlagsData,
  websites: Website[],
  isOnlineMode: boolean
) => IRoute[] = (
  node,
  items,
  currentUser,
  flagsData,
  websites,
  isOnlineMode
) => {
  let itemVisible = true;
  //Leaf route visibility depends on its accessibility
  if (!node.children || node.children.length === 0) {
    const itemVisibleByPermission = isRouteAccessibleByPermission(
      node,
      currentUser
    );
    const itemVisibleByFeatureFlag = isRouteAccessibleByFeatureFlag(
      node,
      flagsData
    );
    const itemVisibleByOffFeatureFlag = isRouteAccessibleByOffFeatureFlag(
      node,
      flagsData
    );
    const itemVisibleWhenOfflineMode =
      isOnlineMode || !!node.supportOfflineMode;

    itemVisible =
      itemVisibleByPermission &&
      itemVisibleByFeatureFlag &&
      itemVisibleByOffFeatureFlag &&
      itemVisibleWhenOfflineMode;

    if (node.isCustomWebsiteItem) {
      const tempiWebsiteId = getTempiWebsiteId(websites);
      const tempiAppId = getTempiAppId(websites);
      // add condition for show CustomWebsiteItem
      // Ref: https://jira.teko.vn/browse/UX4BUYER-5387

      // check user has no permission staff-bff:website-editor:read or flag epic_ecom_480 is turn off
      const isNotApplyEcom480Flow =
        !permissionExactHelpers.checkPermission(
          currentUser.permissions,
          appConstants.STAFF_BFF,
          resourceConstants.WEBSITE_EDITOR,
          actionConstants.READ
        ) || !flagsData[flagConstants.FLAG_KEYS.EPIC_ECOM_480]?.enabled;

      if (outsidePathConstants.CUSTOM_WEBSITE_UI_PATH === node.path) {
        itemVisible = itemVisible && !!tempiWebsiteId && isNotApplyEcom480Flow; // only show Website Interface menu if platform does not follow by epic ECOM_480
        if (tempiWebsiteId && isNotApplyEcom480Flow)
          node.path = getCustomWebsitePath(tempiWebsiteId);
      } else if (outsidePathConstants.CUSTOM_MOBILE_APP_UI_PATH === node.path) {
        itemVisible = itemVisible && !!tempiAppId && isNotApplyEcom480Flow; // only show Mobile App Interface menu if platform does not follow by epic ECOM_480
        if (tempiAppId && isNotApplyEcom480Flow)
          node.path = getCustomMobileAppPath(tempiAppId);
      }
    }

    return itemVisible ? [node] : [];
  } else {
    //Non-leaf node visibility depends on its children visibility
    const children = items.filter(item => node.children?.includes(item.path));
    const visibleChildren = children
      .map(child =>
        getVisibleRoutes(
          child,
          items,
          currentUser,
          flagsData,
          websites,
          isOnlineMode
        )
      )
      .flat(); // [[route1, route2], [route3], []] => [route1, route2, route3]
    return visibleChildren.length > 0 ? [node, ...visibleChildren] : []; //visible if at least one child route accessible
  }
};

/* Given the difference in logic as shown in https://drive.google.com/file/d/1XLqOnL8P77ghSXDsNbGrJOSkJ69CE5_c/view?usp=sharing,
  we have to use a few different functions to handle permission on route */
const isRouteAccessibleByPermission: (
  item: IRoute,
  currentUser: IUserDetailsInfo
) => boolean = (item, currentUser) => {
  const DATA_STUDIO_URL = getSrc(baseDataStudioUrl);
  if (!!DATA_STUDIO_URL && !!item.iFrameSrc?.startsWith(DATA_STUDIO_URL)) {
    return permissionBIReportHelpers.isItemAccessible(
      currentUser.permissions,
      item.iFrameSrc
    );
  } else if (item.usePermissionPrefix) {
    return permissionPrefixHelpers.isItemAccessible(item, [
      ...currentUser.permissions,
      ...currentUser.roles,
    ]);
  } else {
    return permissionExactHelpers.isItemAccessible(
      item,
      currentUser.permissions
    );
  }
};

/**
 * Given the list of feature flag key, determines if a leaf route is accessible when the flag is enabled.
 * By definition, a leaf route has no child routes.
 * @param route the leaf route
 * @param flagsData the list of feature flag key
 */
const isRouteAccessibleByFeatureFlag: (
  item: IRoute,
  flagsData: IFlagsData
) => boolean = (item, flagsData) => {
  const { flagKey } = item;
  if (!flagKey || !flagsData[flagKey]) return true;
  return flagsData[flagKey].enabled;
};

/**
 * Given the list of feature flag key, determines if a leaf route is accessible when the flag is disabled.
 * By definition, a leaf route has no child routes.
 * @param route the leaf route
 * @param flagsData the list of feature flag key
 */
const isRouteAccessibleByOffFeatureFlag: (
  item: IRoute,
  flagsData: IFlagsData
) => boolean = (item, flagsData) => {
  const { hideWhenEnabledFlagKey } = item;
  if (hideWhenEnabledFlagKey && flagsData[hideWhenEnabledFlagKey]?.enabled)
    return false;
  return true;
};

const getCurrentPlatform = () => {
  return localStorage.getItem(CURRENT_PLATFORM) || '';
};

const setCurrentPlatform = (platformId: number) => {
  localStorage.setItem(CURRENT_PLATFORM, platformId.toString());
};

const changePlatform = (platformId: number) => {
  setCurrentPlatform(platformId);
  window.location.reload();
};

const getAuthorizedSellers = (
  currentUser: IUserDetailsInfo | undefined,
  sellers: ISeller[]
) => {
  let authorizedSellers: ISeller[] = [];
  if (currentUser) {
    const currentMetaSellerId = `${_.get(currentUser, [
      'meta_data',
      'seller_id',
    ])}`.trim();
    const configAllSellers = currentMetaSellerId === '0';
    const sellerIds = currentMetaSellerId
      .split(',')
      .map(id => parseInt(id.trim()));
    // Get authorized sellers from IAM user config
    authorizedSellers = configAllSellers
      ? [...sellers]
      : sellers.filter(seller => sellerIds.includes(seller.id));
  }
  return authorizedSellers;
};

const getCurrentSeller = () => {
  return localStorage.getItem(CURRENT_SELLER) || '';
};

const setCurrentSeller = (sellerId: number) => {
  localStorage.setItem(CURRENT_SELLER, sellerId.toString());
};

const changeSeller = (sellerId: number) => {
  setCurrentSeller(sellerId);
  window.location.reload();
};

const logout = () => {
  // Delete current seller and current platform from local storage
  localStorage.removeItem(CURRENT_SELLER);
  localStorage.removeItem(CURRENT_PLATFORM);

  userServices.logout();
};

const getCurrentSite = () => {
  return localStorage.getItem(CURRENT_SITE) || '';
};

const setCurrentSite = (siteId: number) => {
  localStorage.setItem(CURRENT_SITE, siteId.toString());
};

const changeSite = (siteId: number) => {
  setCurrentSite(siteId);
  //FIXME: wait for tracking event recorded when click select site
  setTimeout(() => {
    window.location.reload();
  }, 200);
};

const getSearchUserSites = (userSites: ISite[], keyword: string) => {
  const fieldIncludeKeyword = (field: string) =>
    normalizeLowerCase(field).includes(normalizeLowerCase(keyword));

  return userSites.filter(
    seller => fieldIncludeKeyword(seller.name) || seller.id === Number(keyword)
  );
};

const checkPermission = (
  permissions: string[],
  app: string,
  resource: string,
  action?: string
) => {
  const needCheckedPer = getPermissionCode(app, resource, action);

  return !!(permissions && permissions.find(p => p === needCheckedPer));
};

export default {
  filterHasPermissions,
  getCurrentPlatform,
  setCurrentPlatform,
  changePlatform,
  getAuthorizedSellers,
  getCurrentSeller,
  setCurrentSeller,
  changeSeller,
  getCurrentSite,
  setCurrentSite,
  changeSite,
  getSearchUserSites,
  logout,
  checkPermission,
};
