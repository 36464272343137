import React, { useState, useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { Spin, Result, Modal } from 'antd';
import TekoID from 'teko-oauth2';
import ReactTracker, { TrackerProvider } from 'react-tracker-teko';
import { commonConstants } from 'constants/index';
import { browserHistory, commonHelpers } from 'helpers';
import './App.less';
import './App.scss';
import Page403 from 'containers/shared/Page403';
import Page404 from 'containers/shared/Page404';
import Page500 from 'containers/shared/Page500';
import OfflineRoute from 'components/shared/OfflineRoute';
import PrivateRoute from 'components/shared/PrivateRoute';
import AppLayout from 'containers/AppLayout';
import ContactAdminMessage from 'components/shared/ContactAdminMessage';
import { t } from 'helpers/i18n';
import TekoHelpCenter from 'teko-help-center';
import { localizationHelpers } from 'helpers';
import AppRibbon from 'components/shared/AppRibbon';
import { NetworkContext } from 'contexts/network';
import { userServices } from 'services';
import { db } from 'db';
import configHelpers from 'helpers/config';
import NetworkChangeModal from 'components/shared/NetworkChangeModal';

const { getClientConfig, getCommonConfig } = configHelpers;
const { iam, trackerAppId, code: configCode, swRegistered } = getClientConfig();
const {
  baseTrackerUrl,
  trackerJsFile,
  baseHelpCenterUrl,
  shouldMonitorSessionChangeBySessionId,
} = getCommonConfig();

const { clientId, oauthDomain } = iam;

const { IAM_SCOPES, CODE_SUCCESS, CODE_ERROR } = commonConstants;
const { getCurrentLanguage } = localizationHelpers;
const { trackAPICallTime } = commonHelpers;

new ReactTracker({
  // Configure your tracker server and site by providing
  host: (trackerAppId && baseTrackerUrl) || '',
  urlServeJsFile: (trackerAppId && trackerJsFile) || '',
  appId: trackerAppId,
});

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [isOnlineMode, setIsOnlineMode] = useState(
    !swRegistered || window.navigator.onLine
  );

  useEffect(() => {
    if (!isOnlineMode) {
      setLoading(false);
      return;
    }

    const refreshSession = () => {
      const doRefreshSession = () => {
        TekoID.user.unloadUser();
        window.location.reload();
      };

      Modal.info({
        title: t('YourSessionHasBeenExpired'),
        centered: true,
        onOk: doRefreshSession,
        onCancel: doRefreshSession,
      });
    };

    if (configCode === CODE_SUCCESS) {
      trackAPICallTime(
        TekoID.init({
          clientId,
          scopes: IAM_SCOPES,
          oauthDomain,
          checkTokenRevoked: true,
          monitorSession: true,
          ...(shouldMonitorSessionChangeBySessionId && {
            // Change monitorSessionMethod from default ('iframe') to 'sessionId'
            // to fix a bug where couldn't login on private browsers which blocks third party cookie.
            // See: https://jira.teko.vn/browse/DPOS-1433
            monitorSessionMethod: 'sessionId',
          }),
        }).then(() => {
          // The initial phase is finish.
          // Now you can do your logic.
          setLoading(false);
          // Show alert when session changed and reload app
          if (shouldMonitorSessionChangeBySessionId) {
            TekoID.user.events.addUserSessionIdChanged(refreshSession);
          } else {
            TekoID.user.events.addUserSessionChanged(refreshSession);
          }
        }),
        '/userinfo'
      );
    }

    return () => {
      if (shouldMonitorSessionChangeBySessionId) {
        TekoID.user.events.removeUserSessionIdChanged(refreshSession);
      } else {
        TekoID.user.events.removeUserSessionChanged(refreshSession);
      }
    };
  }, []);

  useEffect(() => {
    if (!isOnlineMode) return;
    const initTekoHelpCenter = async () => {
      if (baseHelpCenterUrl) {
        await TekoHelpCenter.init({
          domain: baseHelpCenterUrl,
          language: getCurrentLanguage(),
        });
      }
    };

    initTekoHelpCenter();
  }, []);

  useEffect(() => {
    const handleUserLoggedIn = async () => {
      if (userServices.isLoggedIn()) {
        const accessToken = userServices.getAccessToken();
        const fullUserInfo = await userServices.getFullUserInfo(isOnlineMode);
        db.currentUser.put({
          ...fullUserInfo,
          accessToken,
        });
      }
    };

    if (!swRegistered || !isOnlineMode) return;
    handleUserLoggedIn();
  }, []);

  if (!configCode || configCode === CODE_ERROR) {
    return (
      <Result
        className="app-result-page"
        status="404"
        title="404"
        subTitle={
          <>
            {t('WebsiteConfigNotFound')}.
            <br />
            <ContactAdminMessage />
          </>
        }
      />
    );
  }

  return (
    <NetworkContext.Provider
      value={{
        isOnlineMode,
        setIsOnlineMode,
      }}
    >
      <AppRibbon />
      {loading ? (
        <Spin className="app-spin" />
      ) : (
        <TrackerProvider history={browserHistory}>
          <Router history={browserHistory}>
            <Switch>
              <Route exact path="/403" name="403" component={Page403} />
              <Route exact path="/404" name="404" component={Page404} />
              <Route exact path="/500" name="500" component={Page500} />
              {isOnlineMode ? (
                <PrivateRoute path="/" name="Home" component={AppLayout} />
              ) : (
                <OfflineRoute path="/" name="Home" component={AppLayout} />
              )}
            </Switch>
          </Router>
        </TrackerProvider>
      )}
      {swRegistered && <NetworkChangeModal />}
    </NetworkContext.Provider>
  );
};

export default App;
