import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18n';
import { I18nextProvider } from 'react-i18next';
import { ConfigProvider, Modal } from 'antd';
import { localizationConstants } from 'constants/index';
import { localizationHelpers } from 'helpers';
import App from './App';
import { registerSW } from 'virtual:pwa-register';
import { t } from 'helpers/i18n';
import configHelpers from 'helpers/config';

const { getClientConfig } = configHelpers;
const clientConfig = getClientConfig();

// add this to prompt for a refresh
if ('serviceWorker' in navigator && !!clientConfig.swRegistered) {
  const updateSW = registerSW({
    onNeedRefresh() {
      Modal.confirm({
        title: t('NewVersionTitle'),
        content: t('UpdateNewVersionMsg'),
        onOk() {
          updateSW(true);
        },
      });
    },
  });
}

const { REGIONS } = localizationConstants;
const { getCurrentLanguage } = localizationHelpers;

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ConfigProvider locale={REGIONS[getCurrentLanguage()].antdLocale}>
      <App />
    </ConfigProvider>
  </I18nextProvider>,
  document.getElementById('root')
);
