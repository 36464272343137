import {
  IGetAllPlatformsResponse,
  IGetAllSellersParams,
  IGetAllSellersResponse,
} from 'interfaces';

export const platforms = [
  {
    id: 1,
    code: 'default',
    name: 'Default',
    numberDisplayFormat: {
      thousandSeparator: '.',
      decimalSeparator: ',',
    },
  },
  {
    id: 2,
    code: 'vnshop',
    name: 'Vnshop',
    numberDisplayFormat: {
      thousandSeparator: '.',
      decimalSeparator: ',',
    },
  },
  {
    id: 3,
    code: 'phongvu',
    name: 'Phong Vu',
    numberDisplayFormat: {
      thousandSeparator: '.',
      decimalSeparator: ',',
    },
  },
];

export const platformInfo = {
  id: 3,
  code: 'phongvu',
  name: 'Phong Vu',
  numberDisplayFormat: {
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
};

export const sellers = [
  {
    fullAddress:
      'Tầng 5, 117,119,121 Nguyễn Du, Phường Bến Thành, Quận 1, Thành phố Hồ Chí Minh',
    provinceCode: '79',
    districtCode: '7901',
    foundingDate: '2020-04-09',
    wardName: 'Phường Bến Thành',
    saleCategoryIDS: [1],
    slogan: 'test 1',
    taxIDNumber: '304998358',
    streetAddress: 'Tầng 5, 117,119,121 Nguyễn Du',
    provinceName: 'Thành phố Hồ Chí Minh',
    id: 1,
    servicePackage: 'full_service',
    brcDate: '2020-04-05',
    districtName: 'Quận 1',
    email: 'cskh@phongvu.vn',
    isAutoGeneratedSKU: 0,
    wardCode: '790104',
    code: 'phongvu',
    logoUrl:
      'https://storage.googleapis.com/teko-gae.appspot.com/media/image/2020/3/11/20200311_a3874aeb-9313-4beb-9eb6-0e25c4bb4e00.png',
    name: 'CÔNG TY CỔ PHẦN THƯƠNG MẠI DỊCH VỤ PHONG VŨ',
    displayName: 'Phong Vũ',
    isActive: 1,
    internationalName: 'PHONG VU TRADING - SERVICE CORPORATION',
    phoneNumber: '2862908777',
    usingGoodsManagementModules: 0,
    brcCode: '304998358',
  },
  {
    fullAddress:
      'Phòng 308A, số 22 Phố Láng Hạ, Phường Cống Vị, Quận Ba Đình, Thành phố Hà Nội',
    provinceCode: '01',
    districtCode: '0101',
    foundingDate: '2020-01-16',
    wardName: 'Phường Cống Vị',
    saleCategoryIDS: [1],
    slogan: null,
    taxIDNumber: '107305822',
    streetAddress: 'Phòng 308A, số 22 Phố Láng Hạ',
    provinceName: 'Thành phố Hà Nội',
    id: 2,
    servicePackage: 'full_service',
    brcDate: '2020-01-16',
    districtName: 'Quận Ba Đình',
    email: 'duongld@vnpay.vn',
    isAutoGeneratedSKU: 0,
    wardCode: '010104',
    code: 'VNS',
    logoUrl: 'https://vnpay.vn:443/wp-content/uploads/2019/10/Text-QR-2.png',
    name: 'CÔNG TY CỔ PHẦN VNPAY SHOP THƯƠNG MẠI DỊCH VỤ',
    displayName: 'VnPay Shop',
    isActive: 1,
    internationalName: 'VNPAY SHOP., JSC',
    phoneNumber: '901737945',
    usingGoodsManagementModules: 0,
    brcCode: '107305822',
  },
];

export const sellerInfo = {
  phoneNumber: '02862908777',
  brcCode: '0304998358',
  wardCode: '790104',
  displayName: 'Phong V\u0169',
  internationalName: 'PHONG VU TRADING - SERVICE CORPORATION',
  email: null,
  streetAddress: 'T\u1ea7ng 5, 117,119,121 Nguy\u1ec5n Du',
  id: 1,
  isAutoGeneratedSKU: 0,
  brcDate: '2007-05-30',
  name:
    'C\u00d4NG TY C\u1ed4 PH\u1ea6N TH\u01af\u01a0NG M\u1ea0I D\u1ecaCH V\u1ee4 PHONG V\u0168',
  isActive: 1,
  taxIDNumber: '0304998358',
  servicePackage: 'full_service',
  districtCode: '7901',
  provinceCode: '79',
  foundingDate: '2007-05-30',
  saleCategoryIDS: [1, 1791],
  fullAddress:
    'T\u1ea7ng 5, 117,119,121 Nguy\u1ec5n Du, Ph\u01b0\u1eddng B\u1ebfn Th\u00e0nh, Qu\u1eadn 1, TP H\u1ed3 Ch\u00ed Minh',
  code: 'PVU',
  districtName: null,
  wardName: null,
  provinceName: null,
  logoUrl: 'https://phongvu.vn/media/wysiwyg/logo-PV-new_2.png',
  slogan: null,
  usingGoodsManagementModules: 0,
};

export const terminalGroups = [
  {
    id: 1,
    code: 'terminalGroup 1',
    name: 'terminal group 1',
  },
  {
    id: 2,
    code: 'terminalGroup 2',
    name: 'terminal group 2',
  },
];

const getAllPlatforms = async (): Promise<IGetAllPlatformsResponse['platforms']> => {
  return platforms;
};

const getAllSellers = (
  params: IGetAllSellersParams
): Promise<IGetAllSellersResponse> => {
  return Promise.resolve({ sellers });
};

export default {
  getAllPlatforms,
  getAllSellers,
};
