export const ORDER_PATHS = {
  Marketplace: '/orders/marketplace',
  MarketplaceSearch: '/orders/marketplace/search',
  MarketplaceDetail: '/orders/marketplace/:id',
  Seller: '/orders/seller',
  SellerDetail: '/orders/seller/:sellerId/:orderId',
  ReturnRequests: '/orders/return-requests',
  CustomerQuotations: '/orders/customer-quotations',
  SyncUpData: '/orders/sync',
  ImportShipmentsStatus: '/orders/import-shipments-status',
};

export const TICKET_PATHS = {
  TicketList: '/tickets/list',
  TicketCreate: '/tickets/create',
  TicketIssueType: '/tickets/issueTypes',
  ProcessTicket: '/tickets/:id/process',
  ImportLead: '/file-import-lead',
  Customers: '/customers',
  Implement: '/implement/list',
  AssignTask: '/implement/assign-task',
  ReportLeadApproaching: '/report/lead-approaching',
  ReportStatus: '/report/status',
  ReportTime: '/report/time',
  ReportChannel: '/report/channel',
  ReportDaily: '/report/daily',
  ReportSummary: '/report/summary',
  TimesheetReport: '/timesheet/report',
};

export const PRODUCT_PATHS = {
  Products: '/products',
  NewProduct: '/products/create',
  ProductDetail: '/products/:id',
  ProductPriceDetail: '/products/:id/price',
  ImportProducts: '/products/import',
  Prices: '/prices',
  PriceDetail: '/prices/:sku',
  InventoryTracking: '/manual-inventory/tracking',
  InventoryTrackingUploadHistory: '/manual-inventory/tracking/upload-history',
  SellerCategories: '/seller_categories',
  Brands: '/brands',
  AttributeSets: '/attribute_sets',
  Attributes: '/attributes',
  ProductVariants: '/web?menu_id=177&action=113',
  TerminalProducts: '/terminal_products',
  ProductRequests: '/product-requests',
  SimilarProducts: '/similar-product',
  PurchaseStatus: '/products/products/:id/purchase-status',
};

export const PROMOTION_PATHS = {
  Campaigns: '/campaigns',
  NewCampaign: '/campaigns/create',
  CampaignDetail: '/campaigns/:id',
  Promotions: '/promotions',
  PromotionDetail: '/promotions/:id',
  ShippingFeePromotions: '/shipping-fee-promotions',
  ShippingFeePromotionCreate: '/shipping-fee-promotions/create',
  ShippingFeePromotionDetail: '/shipping-fee-promotions/:id',
  ComboPromotions: '/combo-promotions',
  QrPromotionCreate: '/qr-promotions/create',
  FlashSales: '/flash-sales',
  NewFlashSales: '/flash-sales/create',
  FlashSaleDetail: '/flash-sales/:id',
  Coupons: '/coupon-groups',
  NewCoupons: '/coupon-groups/create',
  CouponDetail: '/coupon-groups/:id',
  Budgets: '/budgets',
  BudgetDetail: '/budgets/:id',
  GiftCodes: '/gift-codes',
  GiftCodeDetail: '/gift-codes/:id',
  NewGiftCode: '/gift-codes/create',
  CouponTracking: '/coupons/tracking',
  Buckets: '/buckets',
  CheckCoupon: '/admin/user-support-tool',
};

export const CAMPAIGN_MANAGEMENT_PATHS = {
  Transactions: '/history-promotion-messages',
  JourneyBuilder: '/journey-builder',
  NotificationTemplates: '/notification/templates',
  NotificationHistories: '/notification/history',
  NotificationParams: '/notification/params',
  JourneyBuilderDetail: '/journey-builder/detail',
  PromotionDetail: '/promotion-campaign/detail/:id',
};

export const USER_SEGMENT_PATHS = {
  Segments: '/segments',
  UploadSegments: '/customers/files/upload',
};

export const SMART_BANNER_PATHS = {
  SmartBanners: '/smart-banner',
  SmartBannerDetail: '/smart-banner/detail/:id',
};

export const PAGE_BUILDER_PATHS = {
  URLs: '/page-builder/urls',
  UrlCreate: '/page-builder/urls/create',
  UrlDetail: '/page-builder/urls/:id',
  Menus: '/page-builder/menus',
  MenuCreate: '/page-builder/menus/create',
  MenuDetail: '/page-builder/menus/:id',
  BannerTypes: '/page-builder/banner_types',
  BannerTypeCreate: '/page-builder/banner_types/create',
  BannerTypeDetail: '/page-builder/banner_types/:id',
  BannerTypeBannersDetail: '/page-builder/banner_types/:id/banners',
  MarketingCampaigns: '/page-builder/campaigns',
  MarketingCampaignCreate: '/page-builder/campaigns/create',
  MarketingCampaignDetail: '/page-builder/campaigns/:id',
  Banners: '/page-builder/banners',
  BannerCreate: '/page-builder/banners/create',
  BannerDetail: '/page-builder/banners/:id',
  News: '/page-builder/news',
  NewsCreate: '/page-builder/news/create',
  NewsDetail: '/page-builder/news/:id',
  LandingPages: '/page-builder/landing-pages',
  LandingPageCreate: '/page-builder/landing-pages/create',
  LandingPageDetail: '/page-builder/landing-pages/:id',
  LandingBlocks: '/page-builder/landing-blocks',
  LandingBlockDetail: '/page-builder/landing-blocks/:id',
  WebsiteEditors: '/websites',
};

export const PURCHASING_PATHS = {
  StockRequests: '/purchasing/stock-requests',
  StockRequestImportHistory: '/purchasing/stock-requests/import-history',
  PendingOrders: '/purchasing/stock-requests/pending-orders',
  PendingOrdersImportHistory:
    '/purchasing/stock-requests/pending-orders/import-history',
  SupplierPriceList: '/web?menu_id=201&action=118',
  BackorderPlans: '/purchasing/backorder-plans',
  Suppliers: '/web?menu_id=201&action=50',
  SupplierPORequirements: '/purchasing/supplier-po-requirements',
  UploadHistory: '/purchasing/supplier-po-requirements/upload-history',
  SupplierPORequirementDetail:
    '/purchasing/supplier-po-requirements/:supplierId',
  BranchManualRequests: '/web?menu_id=201&action=423',
  SupplierReturnRequests: '/web?menu_id=201&action=486',
  PriceList: '/purchasing/price-list',
  PriceListUploadHistory: '/purchasing/price-list/upload-history',
  PriceListImportSupplyStatusHistory:
    '/purchasing/supply-status/upload-history',
  PriceListCreate: '/purchasing/price-list/create',
  PriceListDetail: '/purchasing/price-list/:id',
  InventoryAlert: '/purchasing/inventory-alert',
  InventoryAlertLog: '/purchasing/inventory-alert/action-history',
  InventoryTrackingByBatch: '/purchasing/inventory-alert/:bucketId/:sku',
  ClearanceTypes: '/clearance-types',
  SupplyStockPlan: '/purchasing/supply-stock-plan',
  SupplyStockPlanHistory: '/purchasing/supply-stock-plan/upload-history',
  PurchaseOrder: '/purchasing/purchase-order',
  PurchaseOrderCreate: '/purchasing/purchase-order/create',
  GoodsComingTracking: '/purchasing/goods-coming-tracking',
  StockTransferCreate: '/purchasing/stock-transfer/quick-create',
  StockTransfer: '/purchasing/stock-transfer',
  InventoryTracking: '/purchasing/inventory-tracking',
  InventoryTrackingUploadHistory:
    '/purchasing/inventory-tracking/upload-history',
  StockImbalanceAlerts: '/purchasing/stock-imbalance-alerts',
  ReceivingTicketSupplier: '/purchasing/receiving-ticket-supplier',
  SiteLeadTime: '/purchasing/site-lead-time/:siteId',
  SupplierCreate: '/purchasing/supplier-management/create',
  RebateProgramList: '/purchasing/rebate/list',
  RebateProgramCreate: '/purchasing/rebate/create',
  RebateProgramDetail: '/purchasing/rebate/:programId',
  ExportHistoryStatic: '/purchasing/export-history',
  ExportHistory: '/purchasing/export-history/:exportType',
  PurchaseOrderReturn: '/purchasing/por',
  PurchaseOrderReturnCreate: '/purchasing/por/create',
  ActionHistory: '/purchasing/action-history/:actionObject/:actionOn',
  StockTransferTracking: '/purchasing/stock-transfer-tracking',
  ImportHistory: '/purchasing/import-history',
  PurchaseReport: '/purchasing/purchase-report',
  ManufactureManagement: '/purchasing/manufacture-management',
  DemandPlanning: '/purchasing/demand-planning',
  BudgetPlanning: '/purchasing/budget-planning',
  Warning: '/purchasing/warning',
  OrderManagement: '/purchasing/order-management',
  ImportSupplierReport: '/purchasing/import-report',
};

export const CASHIER_PATHS = {
  ConfirmOrder: '/#/processing',
  Receipt: '/#/debt',
  OtherPaymentIn: '/#/otherpayment-in',
  Payoo: '/#/collection',
  Refund: '/#/refund',
  OtherPaymentOut: '/#/otherpayment-out',
  EndShift: '/#/endshift',
  RefundRequests: '/cashier/refund-requests',
};

export const FINANCE_WEB_APP_PATHS = {
  CreatePaymentReceiptIn: '/pos/create-payment-in',
  CreatePaymentReceiptOut: '/pos/create-payment-out',
  PaymentReceiptList: '/pos/payment-list',
  ExportHistory: '/cashier/export-history/:exportType',
  CashFundsReport: '/reports/accounting/cash-funds',
};

export const INVOICE_PATHS = {
  CreateSupplierInvoice: '/web?menu_id=145&action=201&view_type=form',
  CreateServiceInvoice: '/service-invoices/create',
  CreateBranchInvoice: '/branch-invoices/create',
  AdjustInvoice: '/#/invoice',
};

export const ACCOUNTING_PATHS = {
  TransactionImport: '/receivables/transaction-importer',
  BankAccounts: '/receivables/banking/accounts',
  BankStatements: '/receivables/banking/statements',
  PendingBankStatements: '/receivables/banking/pending-statements',
  CloseTheBooks: '/receivables/close-the-books',
  CustomerCreditLimits: '/accounting/customer-credit-limits',
  RefundRequests: '/accounting/refund-requests',
  PurchaseInvoiceCreate: '/accounting/purchase-invoices/create',
  PurchaseReturnInvoiceCreate: '/accounting/purchase-return-invoices/create',
};

export const WAREHOUSING_PATHS = {
  Sites: '/manual-inventory/sites',
  UpdateSite: '/manual-inventory/sites/:id',
  CreateSite: '/manual-inventory/sites/create',
  SitesUploadHistory: '/manual-inventory/sites/upload-history',
  Warehouses: '/manual-inventory/warehouses',
  UpdateWarehouse: '/manual-inventory/warehouses/:id',
  CreateWarehouse: '/manual-inventory/warehouses/create',
  UploadHistory: '/manual-inventory/warehouses/upload-history',
  BlockedStock: '/web?menu_id=145&action=871',
  MinStockThreshold: '/web?menu_id=201&action=481',
  ConfigureMaterial: '/web?menu_id=262&action=2835',
  BOM: '/web?menu_id=262&action=399',
  PurchaseReceipts: '/web?menu_id=145&action=565',
  StockImports: '/web?menu_id=145&action=550',
  SalesReturnOrders: '/web?menu_id=145&action=638',
  InboundProcess: '/web?menu_id=145&action=560',
  SalesReturnProcess: '/web?menu_id=145&action=650',
  PurchaseReturnOrders: '/web?menu_id=145&action=652',
  StockTransferOrders: '/web?menu_id=145&action=467',
  ConsignmentSTO: '/web?menu_id=145&action=594',
  StockExports: '/web?menu_id=145&action=551',
  SalesOrders: '/web?menu_id=249&action=344',
  OrdersToPick: '/web?menu_id=145&action=639',
  OrdersReadyForTechnician: '/web?menu_id=145&action=640',
  OrdersToInstall: '/web?menu_id=145&action=641',
  OrdersToPack: '/web?menu_id=145&action=642',
  OrdersReadyForShipper: '/web?menu_id=145&action=643',
  OrdersReadyForCustomer: '/web?menu_id=145&action=1578',
  OrdersAlreadyExported: '/web?menu_id=145&action=765',
  OutboundProcess: '/web?menu_id=145&action=647',
  SerialChangeProcess: '/web?menu_id=145&action=690',
  WarehouseTransaction: '/warehouse-transaction',
  WarehouseTransactionDetail: '/warehouse-transaction/:id',
  StockCountSheet: '/web?menu_id=145&action=873',
  SynthesizeStockCount: '/web?menu_id=145&action=874',
  PerformStockCount: '/web?menu_id=145&action=875',
  ManageBINs: '/manage-bin',
  CreatePickingList: '/picking-list/create',
  ManagePickingList: '/picking-list/management',
  ManagePickingListV2: '/picking-list/management_v2',
  Packing: '/packing',
  Handover: '/handover',
  HandoverPOR: '/handover-por',
  StockAdjustmentRequests: '/stock-adjustment-requests',
  StockAdjustmentRequestIncrement:
    '/stock-adjustment-requests/increment/create',
  StockAdjustmentRequestDecrement:
    '/stock-adjustment-requests/decrement/create',
  StockAdjustmentRequestIncrementDetail:
    '/stock-adjustment-requests/increment/:id',
  StockAdjustmentRequestDecrementDetail:
    '/stock-adjustment-requests/decrement/:id',
  ImportRequests: '/warehouse/inbound-process/import-request',
  ImportRequestDetail: '/warehouse/inbound-process/import-request/:id',
  InboundQualityControl: '/warehouse/quality-control/inbound',
  NormalQualityControl: '/warehouse/quality-control/normal',
  UnbuildOrders: '/web?menu_id=262&action=404',
  ImportStockBalance: '/import-stock-balance',
  StockProductByBin: '/stock-product-by-bin',
  ManufactureSession: '/manufacture/session',
  ManufactureSessionCreate: '/manufacture/session/create',
  ManufactureSessionDetail: '/manufacture/session/:id',
  ManufactureBOM: '/manufacture/bom',
  ManufactureBOMCreate: '/manufacture/bom/create',
  ManufactureBOMDetail: '/manufacture/bom/:id',
  ManufactureSerialComponent: '/manufacture/serial-component',
  ManufactureProcess: '/manufacture/process/:id',
  ExportRequestManagement: '/export/export-requests',
  ExportRequestProgress: '/export/export-request-progress',
  ExportRequestHandling: '/export/export-requests-handling',
  ExportRequestHandlingDetail: '/export/export-requests-handling/:id',
  ExportRequestDetail: '/export/export-requests/:id',
  WarehouseTransactionOmni1248: '/transaction/warehouse-transaction',
  WarehouseTransactionDetailOmni1248: '/transaction/warehouse-transaction/:id',
  StockTransaction: '/transaction/stock-transaction',
  ImportFileProcessingHistory: '/file-processing-history/:importType',
  AuditLogActionHistory: '/audit-log/:serviceCode/:actionObject/:actionOn',
  StockCountSessions: '/stock-count/sessions',
  CreateStockCountSession: '/stock-count/sessions/create',
  StockCountSessionDetail: '/stock-count/sessions/:id',
  StockCountCycles: '/stock-count-cycles',
  InternalStockMovement: '/warehouse/internal-stock-movement',
  SerialTracking: '/warehouse/serial-tracking',
  WMSSerialTracking: '/serial-tracking',
  ReplaceSerial: '/warehouse/replace-serial',
  MovementAllProduct: '/movement-all-product',
};

export const LOGISTIC_PATHS = {
  Dashboard: '/dashboard/operate',
  MergeBoLs: '/bls-group',
  PrintLabels: '/bls/label',
  ErrorRequests: '/message/approve-error-msg',
  BusLines: '/admin/bus-line',
  ExportDataForHandover: '/transports/export',
  ExportCustomData: '/transports/export-request',
  ExportDataForComparison: '/transports/recheck',

  /**
   * @created by OMNI-1161
   */
  RouteManagement: '/logistics/routes-management',
  RouteManagementConfiguration: '/logistics/routes-management/create',
  RouteManagementDetail: '/logistics/routes-management/:id',

  /**
   * @created by OMNI-1147
   */
  DeliveryRequests: '/logistics/delivery-requests',
  DeliveryRequestDetail: '/logistics/delivery-requests/:id',
  ActionHistory:
    '/logistics/action-history/:serviceCode/:actionObject/:actionOn',

  /**
   * @created by OMNI-1155
   */
  DeliveryReport: '/logistics/delivery-report',
};

export const CUSTOMER_MANAGEMENT_PATHS = {
  Dealers: '/dealers',
};

export const STAFF_MANAGEMENT_PATHS = {
  Users: '/users',
  BusinessGroups: '/business-groups',
  StaffList: '/staffs',
  NewBusinessGroup: '/business-groups/create',
  DetailBusinessGroup: '/business-groups/:id',
  NewStaff: '/staffs/create',
  DetailStaff: '/staffs/:id',
};

export const REPORT_PATHS = {
  SellerSaleReport: '/reports/seller-sale-report',
  GraphSaleReport: '/reports/graph-sale-report',
  TabularSaleReport: '/reports/tabular-sale-report',
  SelloutReport: '/reports/sellout-report',
  ReceivablesReportByOrder: '/receivables/reports/report-by-order',
  ReceivablesReportByObject: '/receivables/reports/report-by-object',
  GoodsTransactions: '/receivables/transactions/type-goods',
  MoneyTransactions: '/receivables/transactions/type-money',
  OutVatReport: '/accounting/vat-out-report',
  InVatReport: '/accounting/vat-in-report',
  BranchVatReport: '/accounting/vat-branch-report',
  OtherAccReports: '/receivables/general-report',
  DebtReport: '/reports/debt-report',
  BankReconciliations: '/receivables/banking/reconciliations',
  StockTransferOut: '/reports/stock-transfer-out',
  StockImportReport: '/web?menu_id=145&action=789',
  StockExportReport: '/web?menu_id=145&action=790',
  StockMovementSummary: '/web?menu_id=490&action=791',
  StockCard: '/web?menu_id=145&action=792',
  InventoryReport: '/reports/inventory-report',
  ShippingReport: '/report/vehicle-report',
  ShippingExpenseReport: '/transports/estimate-fee-report',
  SalesOrderReportBySaleman: '/reports/sales-order',
  SiteTransferReport: '/reports/internal-site-transfer',
  StockSummaryReport: '/reports/stock-summary',
  StockSummaryWithCogsReport: '/reports/stock-summary-with-cogs',
  StockSummaryByLotReport: '/reports/stock-summary-by-lot',
  WarehouseTransactionReport: '/reports/warehouse-transaction',
  WarehouseTransactionReportV2: '/reports/warehouse-transaction-v2',
  WarehouseTransactionWithCogsReport:
    '/reports/warehouse-transaction-with-cogs',
  RevenueReport: '/reports/revenue',
  StockCountSessionReport: '/reports/stock-count-session',
  StockTransactionReport: '/reports/stock-transaction',
  SaleOrderReturnWithInvoiceReport:
    '/reports/accounting/sor-issued-invoice-report',
  SaleOrderReturnWithoutInvoiceReport:
    '/reports/accounting/sor-non-issued-invoice-report',
  SaleOrderWithInvoiceReport: '/reports/accounting/so-issue-invoice-report',
  SaleOrderWithoutInvoiceReport:
    '/reports/accounting/so-non-issue-invoice-report',
  PurchaseReceiptReport: '/reports/accounting/purchase-receipt-report',
  PurchaseReturnIssueReport: '/reports/accounting/purchase-return-issue-report',
  OtherReceiptReport: '/reports/accounting/other-receipt-report',
  OtherIssueReport: '/reports/accounting/other-issue-report',
  SerialStockReport: '/reports/serial-stock',
  DiscountApprovedOrdersReport: '/reports/discount-approved-orders',
  EndOfShiftReport: '/reports/accounting/end-of-shift-report',
  CashBalanceReport: '/reports/accounting/cash-balance-report',
  StockSummaryConsignmentReport: '/reports/stock-summary-consignment',
  CurrentStockReport: '/reports/current-stock',
  PaymentReceiptReport: '/reports/accounting/payment-receipt-report',
  ImportInternalSiteTransferReport:
    '/reports/accounting/import-internal-site-transfer-report',
  ExportInternalSiteTransferReport:
    '/reports/accounting/export-internal-site-transfer-report',
  ConsignmentSalesReport: '/reports/consignment-sales',
};

export const SETTING_PATHS = {
  DiscountApproval: '/setting/discount-approval',
  ReturnReasons: '/setting/return-reasons',
  LoyaltyProgram: '/setting/loyalty-program',
  MarketplaceConnector: '/marketplace-connector',
  ShippingServices: '/shipping-services',
  AdditionalServices: '/additional-services',
  CODServices: '/cod-services',
  Policies: '/policies',
  PaymentMethods: '/payment-methods',
  DeliveryProviders: '/delivery-providers',
  SposDevices: '/terminals-sposes',
  SynonymList: '/synonyms',
  ShippingServicesCreate: '/shipping-services/policy/create',
  ShippingServicesDetail: '/shipping-services/policy/:policyId',
  AdditionalServicesCreate: '/additional-services/policy/create',
  AdditionalServicesDetail: '/additional-services/policy/:policyId',
  DebtApprovalConfig: '/setting/debt-approval-config',
};

export const DPOS_PATHS = {
  EndShift: '/end-shift',
  EndShiftHistory: '/end-shift/history',
  Orders: '/orders',
  CloneOrder: '/clone-order',
};

export const USER_NOTIFICATION_PATHS = {
  NotificationList: '/notification/listing',
  NotificationCreate: '/notification/create-notification',
  NotificationHistory: '/template/sent-history',
  TemplateList: '/template/list',
  ParamList: '/template/params',
  ReportList: '/report',
  ZaloIntegrate: '/zalo/integrate',
};

export const LOYALTY_SERVICE_PATHS = {
  TransactionList: '/loyalty/transactions',
  TransactionPointList: '/loyalty/transaction-points',
  TransactionSystemList: '/loyalty/system-transactions',
  BalanceReport: '/loyalty/report/balance',
  TransactionReport: '/loyalty/transaction-report',
  MemberList: '/loyalty/members',
  CampaignList: '/campaign/list',
  DetailCampaign: '/campaign/detail/:id',
  CreateCampaign: '/campaign/create',
};

export const HELPDESK_TICKET_PATH = '/tickets';

export const ENTITY_PATHS = {
  Platforms: '/platforms',
  Sellers: '/sellers',
  TerminalGroups: '/terminal-groups',
  Terminals: '/terminals',
  Providers: '/providers',
  TerminalDetail: '/terminals/:id',
};
export const ORDER_HEALTH_PATH = '/order-health';

export const SYSTEM_CATALOG_PATHS = {
  MasterCategories: '/master_categories',
  Units: '/units',
  WarehouseLocations: '/web?action=266&menu_id=145',
  CategoryMapping: '/seller_categories/mapping',
  ShippingTypes: '/shipping_types',
};

export const FLAGSUP_ADMIN_PATH = '/admin';

export const SYNONYMS_LIST_PATH = '/synonyms';

export const SYSTEM_ADMIN_PATHS = {
  SupportTool: '/admin/support-tool',
  SQLLookup: '/admin/sql-lookup',
  CatalogAdmin: '/admin/catalog',
  StaffAdmin: '/admin/staff-admin',
  PolicyAdmin: '/admin/policy',
  UserAdmin: '/admin/user',
};

export const SEO_MANAGEMENT_PATHS = {
  Redirect: '/redirection',
};

export const MENU_LIST_PATH = '/menu-directory';

export const BI_REPORT_V2_PATHS = {
  MarketingLead: '/superset/dashboard/1/',
  Operation: '/superset/dashboard/2/',
  Merchandise: '/superset/dashboard/3/',
  Sales: '/superset/dashboard/4/',
  WarehouseManagement: '/superset/dashboard/5/',
  SupplyChain: '/superset/dashboard/6/',
  BOD: '/superset/dashboard/7/',
  LOYALTY: '/superset/dashboard/12/',
};

export const BI_REPORT_V2_PATHS_LIVE = {
  MarketingLead: '/superset/dashboard/32/',
  Operation: '/superset/dashboard/33/',
  Merchandise: '/superset/dashboard/6/',
  Sales: '/superset/dashboard/35/',
  WarehouseManagement: '/superset/dashboard/39/',
  SupplyChain: '/superset/dashboard/34/',
  BOD: '/superset/dashboard/40/',
  MOIT: '/superset/dashboard/97',
  POPM: '/superset/dashboard/87/',
  LOYALTY: '/superset/dashboard/172/',
};

export const ORDER_NOTIFICATION_PATH = {
  List: '/order-notifications',
  Create: '/order-notifications/create',
  Update: '/order-notifications/:id',
  Clone: '/order-notifications/clone/:id',
};
