import React, { useContext, useMemo, useState } from 'react';
import { Menu, Dropdown, Input, Empty } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { sellerHelpers, userHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { StoreContext } from 'contexts';
import PlatformItem from 'components/Platform/PlatformItem';
import './PlatformGlobalSwitch.scss';

const { getSearchPlatforms } = sellerHelpers;
const { getCurrentPlatform } = userHelpers;

const PlatformGlobalSwitch: React.FC = () => {
  const { authorizedPlatforms, platformInfo } = useContext(StoreContext);
  const [keyword, setKeyword] = useState('');
  const [visible, setVisible] = useState(false);
  const currentPlatform = getCurrentPlatform();

  const results = useMemo(() => {
    return getSearchPlatforms(authorizedPlatforms, keyword);
  }, [keyword, authorizedPlatforms]);

  if (!platformInfo) {
    return null;
  }

  const platformMenu = (
    <Menu className="platform-menu" defaultSelectedKeys={[currentPlatform]}>
      <Menu.Item className="platform-search-item">
        <Input
          data-testid="platform-search-input"
          autoFocus
          allowClear
          placeholder={t('SearchPlatformPlaceholder')}
          prefix={<SearchOutlined />}
          onChange={e => setKeyword(e.target.value.trim())}
        />
      </Menu.Item>
      {!!results.length ? (
        results.map(platform => (
          <Menu.Item key={platform.id} className="py-0">
            <PlatformItem platform={platform} miniSize />
          </Menu.Item>
        ))
      ) : (
        <Menu.Item>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('NoResult')}
          />
        </Menu.Item>
      )}
    </Menu>
  );

  const labelInfo = () => {
    return (
      <span className="app-user">
        <span className="label">Platform: {platformInfo.name}</span>
        {authorizedPlatforms.length !== 1 ? <DownOutlined /> : null}
      </span>
    );
  };

  return (
    <span id="platform-global-switch">
      {authorizedPlatforms.length === 1 ? (
        labelInfo()
      ) : (
        <Dropdown
          overlay={platformMenu}
          trigger={['click']}
          onVisibleChange={flag => setVisible(flag)}
          visible={visible}
          getPopupContainer={() =>
            document.getElementById('platform-global-switch') as HTMLElement
          }
        >
          {labelInfo()}
        </Dropdown>
      )}
    </span>
  );
};

export default PlatformGlobalSwitch;
