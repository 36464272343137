/**
 * Generates a suitable screenName based on route path,
 * following this format:
 * - / --> .
 * - /orders/marketplace --> .orders.marketplace
 * - /orders/sellers/:sellerId/:id --> .orders.sellers.&sellerId.&id
 *
 * @param path route path
 * @returns screenName to use in tracker-js
 */
const computeTrackingScreenName = (path: string) => {
  const PATTERN_REGEX = /[/:]/g;
  const replacedPattern: Record<string, string> = {
    '/': '.',
    ':': '&',
  };
  return path.replace(PATTERN_REGEX, value => replacedPattern[value]);
};

export default {
  computeTrackingScreenName,
};
