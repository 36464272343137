import { lazy } from 'react';
import { ShopOutlined } from '@ant-design/icons';
import {
  appConstants,
  resourceConstants,
  roleConstants,
  actionConstants,
  guideConstants,
  flagConstants,
} from 'constants/index';
import { WAREHOUSING_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import { withSiteIdPermission } from 'hocs';
import configHelpers from 'helpers/config';
import { ManualTrackingScreenName } from 'interfaces';

const { getCommonConfig } = configHelpers;
const {
  baseWmsUrl,
  baseSrmUrl,
  baseErpUrl,
  baseNewWmsUrl,
  baseSposUrl,
} = getCommonConfig();

const { STAFF_BFF, WMS } = appConstants;
const {
  PHONGVU,
  POSTMART_MANAGER,
  POSTMART_SALESMAN,
  TAKASHIMAYA,
  SOIBIEN,
  SOIBIEN_STORE_STAFF,
  SOIBIEN_WAREHOUSE_STAFF,
  WMS_UNBUILD_ORDERS,
} = roleConstants;
const {
  INVENTORY,
  PICKING_LIST,
  BINS,
  IMPORT_REQUEST,
  QUALITY_CONTROL,
  PACKING,
  HANDOVER,
  STOCK_ADJUSTMENT,
  WAREHOUSE_TRANSACTION,
  WAREHOUSE_STOCK_COUNT_SESSION,
  WAREHOUSE_STOCK_COUNT_CYCLE,
  STOCK_BALANCE,
  MANUFACTURE,
  WAREHOUSE_EXPORT_REQUEST,
  STOCK_PRODUCT_BY_BIN,
  STOCK_TRANSACTION,
  AUDIT_LOG,
  IMPORT_HISTORY,
  MOVEMENT,
  SERIAL_TRACKING,
  MOVEMENT_ALL_PRODUCT,
} = resourceConstants;
const {
  READ_SITE_AND_WAREHOUSE,
  READ,
  READ_PROGRESS,
  READ_ALL,
  READ_POR,
  UPDATE,
  UPDATE_IMPORT,
  READ_SESSION,
  CREATE,
  CREATE_SESSION,
  READ_BOM,
  CREATE_BOM,
  READ_WH,
  UPDATE_INBOUND,
  UPDATE_NORMAL,
  UPDATE_WEB,
  RECORD_WEB,
  READ_WEB,
  UPDATE_SERIAL_WEB,
  READ_SERIAL_CPN,
} = actionConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;
const { FLAG_KEYS, EXTENDED_FLAG_KEYS } = flagConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));
const IFrameAuditLog = lazy(() => import('containers/shared/IFrameAuditLog'));

const warehouseTransactionRoutes = [
  {
    path: '/warehousing/transaction',
    name: t('Warehousing.WarehouseTransactionManagement'),
    children: [
      '/warehousing/transaction/warehouse-transaction',
      '/warehousing/transaction/stock-transaction',
    ],
  },
  {
    exact: true,
    path: '/warehousing/transaction/warehouse-transaction',
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      WAREHOUSING_PATHS.WarehouseTransactionOmni1248
    ),
    name: t('Warehousing.TransactionByRequest'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, WAREHOUSE_TRANSACTION, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.WMS.WMS,
      userGuideCode: USER_GUIDE_CODE.WMS.WMSTRANSACTION_MANAGE,
    },
  },
  {
    exact: true,
    path: '/warehousing/transaction/warehouse-transaction/:id',
    name: t('Warehousing.WarehouseTransactionDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      WAREHOUSING_PATHS.WarehouseTransactionDetailOmni1248
    ),
    permissions: [permission(STAFF_BFF, WAREHOUSE_TRANSACTION, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/transaction/stock-transaction',
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.StockTransaction),
    name: t('Warehousing.StockTransaction'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, STOCK_TRANSACTION, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
];

const warehousingRoutes = [
  // Level 1 menu
  {
    path: '/warehousing',
    name: t('Warehousing.Root'),
    icon: ShopOutlined,
    children: [
      '/warehousing/master-data',
      '/warehousing/import',
      '/warehousing/export',
      '/warehousing/manufacture',
      '/warehousing/stock-count',
      '/warehousing/stock-adjustment',
      '/warehousing/warehouse-transaction', //TODO: remove after release OMNI-1248
      '/warehousing/transaction',
      '/warehousing/stock-adjustment-requests',
      '/warehousing/internal-stock-movement',
      '/warehousing/movement-all-product',
    ],
  },
  // Level 2 menus
  {
    path: '/warehousing/master-data',
    name: t('Warehousing.MasterData'),
    children: [
      '/warehousing/master-data/sites',
      '/warehousing/master-data/warehouses',
      '/warehousing/master-data/bin-location',
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.BlockedStock),
      getSrc(baseSrmUrl, WAREHOUSING_PATHS.MinStockThreshold),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.ConfigureMaterial),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.BOM),
    ],
  },
  {
    path: '/warehousing/import',
    name: t('Warehousing.Import'),
    children: [
      '/warehousing/import/requests',
      '/warehousing/import/quality-control/inbound',
      '/warehousing/import/quality-control/normal',
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.PurchaseReceipts),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.StockImports),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.SalesReturnOrders),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.InboundProcess),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.SalesReturnProcess),
    ],
  },
  {
    path: '/warehousing/export',
    name: t('Warehousing.Export'),
    children: [
      '/warehousing/export/export-requests',
      '/warehousing/export/export-request-progress',
      '/warehousing/export/export-requests-handling',
      '/warehousing/export/picking-list/manage',
      '/warehousing/export/picking-list/manage_v2',
      '/warehousing/export/picking-list/create',
      '/warehousing/export/packing',
      '/warehousing/export/handover',
      '/warehousing/export/handover-por',
      '/warehousing/export/replace-serial',
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.PurchaseReturnOrders),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.StockTransferOrders),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.ConsignmentSTO),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.StockExports),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.SalesOrders),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersToPick),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersReadyForTechnician),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersToInstall),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersToPack),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersReadyForShipper),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersReadyForCustomer),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersAlreadyExported),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.OutboundProcess),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.SerialChangeProcess),
    ],
  },
  {
    path: '/warehousing/manufacture',
    name: t('Warehousing.Manufacture'),
    children: [
      '/warehousing/manufacture/session',
      '/warehousing/manufacture/bom',
      '/warehousing/manufacture/serial-component',
    ],
  },
  {
    path: '/warehousing/stock-count',
    name: t('Warehousing.StockCount'),
    children: [
      '/warehousing/stock-count/import-stock-balance',
      '/warehousing/stock-count/stock-product-by-bin',
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.StockCountSheet),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.SynthesizeStockCount),
      getSrc(baseWmsUrl, WAREHOUSING_PATHS.PerformStockCount),
      '/warehousing/stock-count/sessions',
      '/warehousing/stock-count/cycles',
      '/warehousing/stock-count/serial-tracking',
    ],
  },
  {
    exact: true,
    path: '/warehousing/stock-adjustment-requests',
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.StockAdjustmentRequests),
    name: t('Warehousing.StockAdjustmentRequestManagement'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, STOCK_ADJUSTMENT, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    manualTrackingScreenName: ManualTrackingScreenName.PICKING_LIST_MANAGEMENT,
  },
  {
    path: '/warehousing/stock-adjustment',
    name: t('Warehousing.StockAdjustment'),
    children: [getSrc(baseWmsUrl, WAREHOUSING_PATHS.UnbuildOrders)],
  },
  ...warehouseTransactionRoutes,
  {
    exact: true,
    path: '/warehousing/internal-stock-movement',
    name: t('Warehousing.InternalStockMovement'),
    iFrameSrc: getSrc(baseSposUrl, WAREHOUSING_PATHS.InternalStockMovement),
    component: withSiteIdPermission(
      IFrameContainer,
      t('Warehousing.InternalStockMovement')
    ),
    hideTitle: true,
    permissions: [permission(STAFF_BFF, MOVEMENT, UPDATE_WEB)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    isUseMobileView: true,
  },
  // Level 3 menus
  {
    exact: true,
    path: '/warehousing/master-data/sites',
    name: t('Warehousing.Sites'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, WAREHOUSING_PATHS.Sites),
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.WMS.WMS,
      userGuideCode: USER_GUIDE_CODE.WMS.SITE,
    },
  },
  {
    exact: true,
    path: '/warehousing/master-data/sites/create',
    name: t('Warehousing.CreateSite'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, WAREHOUSING_PATHS.CreateSite),
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
    hidePlatformSwitch: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/master-data/sites/upload-history',
    name: t('Warehousing.UploadHistory'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, WAREHOUSING_PATHS.SitesUploadHistory),
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
  },
  {
    exact: true,
    path: '/warehousing/master-data/sites/:id',
    name: t('Warehousing.UpdateSite'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, WAREHOUSING_PATHS.UpdateSite),
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
    hidePlatformSwitch: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/master-data/warehouses',
    name: t('Warehousing.Warehouses'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, WAREHOUSING_PATHS.Warehouses),
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.WMS.WMS,
      userGuideCode: USER_GUIDE_CODE.WMS.WAREHOUSE,
    },
  },
  {
    exact: true,
    path: '/warehousing/master-data/warehouses/create',
    name: t('Warehousing.CreateWarehouse'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, WAREHOUSING_PATHS.CreateWarehouse),
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
  },
  {
    exact: true,
    path: '/warehousing/master-data/warehouses/upload-history',
    name: t('Warehousing.UploadHistory'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, WAREHOUSING_PATHS.UploadHistory),
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
  },
  {
    exact: true,
    path: '/warehousing/master-data/warehouses/:id',
    name: t('Warehousing.UpdateWarehouse'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, WAREHOUSING_PATHS.UpdateWarehouse),
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
    hidePlatformSwitch: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/master-data/bin-location',
    name: t('Warehousing.BINLocation'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ManageBINs),
    permissions: [permission(STAFF_BFF, BINS, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.WMS.WMS,
      userGuideCode: USER_GUIDE_CODE.WMS.BIN_LOCATION,
    },
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.BlockedStock),
    name: t('Warehousing.BlockedStock'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseSrmUrl, WAREHOUSING_PATHS.MinStockThreshold),
    name: t('Warehousing.MinStockThreshold'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.ConfigureMaterial),
    name: t('Warehousing.ConfigureMaterial'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, SOIBIEN)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.BOM),
    name: t('Warehousing.BOM'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, SOIBIEN)],
    usePermissionPrefix: true,
  },
  // Mobile version
  {
    exact: true,
    path: '/warehousing/import/requests',
    name: t('Warehousing.ImportRequestManagement'),
    iFrameSrc: getSrc(baseSposUrl, WAREHOUSING_PATHS.ImportRequests),
    component: IFrameContainer,
    hideTitle: true,
    isNotUseMobileViewWhenEnabledFlagKey:
      FLAG_KEYS.STOCK_IMPORT_REQUEST_DESKTOP,
    permissions: [permission(STAFF_BFF, IMPORT_REQUEST, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    manualTrackingScreenName:
      ManualTrackingScreenName.STOCK_IMPORT_REQUEST_LIST,
  },
  {
    exact: true,
    path: '/warehousing/import/requests/:id',
    name: t('Warehousing.ImportRequestDetail'),
    iFrameSrc: getSrc(baseSposUrl, WAREHOUSING_PATHS.ImportRequestDetail),
    component: IFrameContainer,
    hideTitle: true,
    isNotUseMobileViewWhenEnabledFlagKey:
      FLAG_KEYS.STOCK_IMPORT_REQUEST_DESKTOP,
    permissions: [permission(STAFF_BFF, IMPORT_REQUEST, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    manualTrackingScreenName:
      ManualTrackingScreenName.STOCK_IMPORT_REQUEST_DETAIL,
  },
  {
    exact: true,
    path: '/warehousing/import/quality-control/inbound',
    name: t('Warehousing.InboundQualityControl'),
    iFrameSrc: getSrc(baseSposUrl, WAREHOUSING_PATHS.InboundQualityControl),
    component: withSiteIdPermission(
      IFrameContainer,
      t('Warehousing.InboundQualityControl')
    ),
    hideTitle: true,
    permissions: [permission(STAFF_BFF, QUALITY_CONTROL, UPDATE_INBOUND)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    isUseMobileView: true,
    showSiteSwitchWhenEnabledFlagKey: FLAG_KEYS.SHOW_GSS_QC,
  },
  {
    exact: true,
    path: '/warehousing/import/quality-control/normal',
    name: t('Warehousing.NormalQualityControl'),
    iFrameSrc: getSrc(baseSposUrl, WAREHOUSING_PATHS.NormalQualityControl),
    component: withSiteIdPermission(
      IFrameContainer,
      t('Warehousing.NormalQualityControl')
    ),
    hideTitle: true,
    permissions: [permission(STAFF_BFF, QUALITY_CONTROL, UPDATE_NORMAL)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    isUseMobileView: true,
    showSiteSwitchWhenEnabledFlagKey: FLAG_KEYS.SHOW_GSS_QC,
  },

  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.PurchaseReceipts),
    name: t('Warehousing.PurchaseReceipts'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU), permission(WMS, TAKASHIMAYA)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.StockImports),
    name: t('Warehousing.StockImports'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_STORE_STAFF),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.SalesReturnOrders),
    name: t('Warehousing.SalesReturnOrders'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU), permission(WMS, TAKASHIMAYA)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.InboundProcess),
    name: t('Warehousing.InboundProcess'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_STORE_STAFF),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.SalesReturnProcess),
    name: t('Warehousing.SalesReturnProcess'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_STORE_STAFF),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/warehousing/export/export-requests',
    name: t('Warehousing.ExportRequestManagement'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ExportRequestManagement),
    permissions: [permission(STAFF_BFF, WAREHOUSE_EXPORT_REQUEST, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/export/export-request-progress',
    name: t('Warehousing.ExportRequestProgress'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ExportRequestProgress),
    permissions: [
      permission(STAFF_BFF, WAREHOUSE_EXPORT_REQUEST, READ_PROGRESS),
    ],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    manualTrackingScreenName: ManualTrackingScreenName.EXPORT_PROGRESS,
  },
  {
    exact: true,
    path: '/warehousing/export/export-requests/:id',
    name: t('Warehousing.ExportRequestDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ExportRequestDetail),
    permissions: [permission(STAFF_BFF, WAREHOUSE_EXPORT_REQUEST, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/export/picking-list/manage',
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ManagePickingList),
    name: t('Warehousing.ManagePickingList'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, PICKING_LIST, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    manualTrackingScreenName: ManualTrackingScreenName.PICKING_LIST_MANAGEMENT,
  },
  {
    exact: true,
    path: '/warehousing/export/picking-list/create',
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.CreatePickingList),
    name: t('Warehousing.CreatePickingList'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, PICKING_LIST, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.WMS.WMS,
      userGuideCode: USER_GUIDE_CODE.WMS.PICKINGLIST_CREATE,
    },
    showSiteSwitch: true,
    manualTrackingScreenName: ManualTrackingScreenName.CREATE_PICKING_LIST,
  },
  {
    exact: true,
    path: '/warehousing/export/packing',
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.Packing),
    name: t('Warehousing.Packing'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, PACKING, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.WMS.WMS,
      userGuideCode: USER_GUIDE_CODE.WMS.PACK_CREATE,
    },
    hideWhenEnabledFlagKey: FLAG_KEYS.EXPORT_REQUEST_HANDLING,
  },
  {
    exact: true,
    path: '/warehousing/export/export-requests-handling',
    name: t('Warehousing.HandleExportRequest'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ExportRequestHandling),
    permissions: [permission(STAFF_BFF, PACKING, UPDATE)],
    flagKey: FLAG_KEYS.EXPORT_REQUEST_HANDLING,
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    manualTrackingScreenName: ManualTrackingScreenName.EXPORT_REQUEST_HANDLING,
  },
  {
    exact: true,
    path: '/warehousing/export/export-requests-handling/:id',
    name: t('Warehousing.HandleExportRequest'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      WAREHOUSING_PATHS.ExportRequestHandlingDetail
    ),
    permissions: [permission(STAFF_BFF, PACKING, UPDATE)],
    flagKey: FLAG_KEYS.EXPORT_REQUEST_HANDLING,
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    manualTrackingScreenName: ManualTrackingScreenName.EXPORT_REQUEST_HANDLING,
  },
  {
    exact: true,
    path: '/warehousing/export/handover',
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.Handover),
    name: t('Warehousing.Handover'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, HANDOVER, READ_ALL)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    manualTrackingScreenName: ManualTrackingScreenName.HANDOVER,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.WMS.WMS,
      userGuideCode: USER_GUIDE_CODE.WMS.DELIVERY_CREATE,
    },
  },
  {
    exact: true,
    path: '/warehousing/export/handover-por',
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.HandoverPOR),
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    name: t('Warehousing.HandoverPOR'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, HANDOVER, READ_POR)],
    manualTrackingScreenName: ManualTrackingScreenName.HANDOVER_POR,
  },
  {
    exact: true,
    path: '/warehousing/export/replace-serial',
    iFrameSrc: getSrc(baseSposUrl, WAREHOUSING_PATHS.ReplaceSerial),
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    isUseMobileView: true,
    name: t('Warehousing.ReplaceSerial'),
    component: IFrameContainer,
    permissions: [
      permission(STAFF_BFF, WAREHOUSE_EXPORT_REQUEST, UPDATE_SERIAL_WEB),
    ],
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.PurchaseReturnOrders),
    name: t('Warehousing.PurchaseReturnOrders'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU), permission(WMS, TAKASHIMAYA)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.StockTransferOrders),
    name: t('Warehousing.StockTransferOrders'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_STORE_STAFF),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.ConsignmentSTO),
    name: t('Warehousing.ConsignmentSTO'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.StockExports),
    name: t('Warehousing.StockExports'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_STORE_STAFF),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.SalesOrders),
    name: t('Warehousing.SalesOrders'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, TAKASHIMAYA)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersToPick),
    name: t('Warehousing.OrdersToPick'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersReadyForTechnician),
    name: t('Warehousing.OrdersReadyForTechnician'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersToInstall),
    name: t('Warehousing.OrdersToInstall'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersToPack),
    name: t('Warehousing.OrdersToPack'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersReadyForShipper),
    name: t('Warehousing.OrdersReadyForShipper'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU), permission(WMS, TAKASHIMAYA)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersReadyForCustomer),
    name: t('Warehousing.OrdersReadyForCustomer'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.OrdersAlreadyExported),
    name: t('Warehousing.OrdersAlreadyExported'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.OutboundProcess),
    name: t('Warehousing.OutboundProcess'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_STORE_STAFF),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.SerialChangeProcess),
    name: t('Warehousing.SerialChangeProcess'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.StockCountSheet),
    name: t('Warehousing.StockCountSheet'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.SynthesizeStockCount),
    name: t('Warehousing.SynthesizeStockCount'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.PerformStockCount),
    name: t('Warehousing.PerformStockCount'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/warehousing/stock-adjustment-requests/increment/create',
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      WAREHOUSING_PATHS.StockAdjustmentRequestIncrement
    ),
    name: t('Warehousing.StockAdjustmentRequestIncrement'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, STOCK_ADJUSTMENT, CREATE)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    manualTrackingScreenName: ManualTrackingScreenName.CREATE_PICKING_LIST,
  },
  {
    exact: true,
    path: '/warehousing/stock-adjustment-requests/decrement/create',
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      WAREHOUSING_PATHS.StockAdjustmentRequestDecrement
    ),
    name: t('Warehousing.StockAdjustmentRequestDecrement'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, STOCK_ADJUSTMENT, CREATE)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/stock-adjustment-requests/increment/:id',
    name: t('Warehousing.StockAdjustmentRequestIncrementDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      WAREHOUSING_PATHS.StockAdjustmentRequestIncrementDetail
    ),
    permissions: [permission(STAFF_BFF, STOCK_ADJUSTMENT, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/stock-adjustment-requests/decrement/:id',
    name: t('Warehousing.StockAdjustmentRequestDecrementDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      WAREHOUSING_PATHS.StockAdjustmentRequestDecrementDetail
    ),
    permissions: [permission(STAFF_BFF, STOCK_ADJUSTMENT, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, WAREHOUSING_PATHS.UnbuildOrders),
    name: t('Warehousing.UnbuildOrders'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(STAFF_BFF, WMS_UNBUILD_ORDERS)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/warehousing/stock-count/import-stock-balance',
    name: t('Warehousing.ImportStockBalance'),
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ImportStockBalance),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, STOCK_BALANCE, UPDATE_IMPORT)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
    manualTrackingScreenName: ManualTrackingScreenName.IMPORT_STOCK_BALANCE,
  },
  {
    exact: true,
    path: '/warehousing/stock-count/stock-product-by-bin',
    name: t('Warehousing.StockProductByBin'),
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.StockProductByBin),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, STOCK_PRODUCT_BY_BIN, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/manufacture/session',
    name: t('Warehousing.ManufactureSession'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ManufactureSession),
    permissions: [permission(STAFF_BFF, MANUFACTURE, READ_SESSION)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/manufacture/session/create',
    name: t('Warehousing.ManufactureSessionCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      WAREHOUSING_PATHS.ManufactureSessionCreate
    ),
    permissions: [permission(STAFF_BFF, MANUFACTURE, CREATE_SESSION)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/manufacture/session/:id',
    name: t('Warehousing.ManufactureSessionDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      WAREHOUSING_PATHS.ManufactureSessionDetail
    ),
    permissions: [permission(STAFF_BFF, MANUFACTURE, READ_SESSION)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/manufacture/bom',
    name: t('Warehousing.ManufactureBOM'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ManufactureBOM),
    permissions: [permission(STAFF_BFF, MANUFACTURE, READ_BOM)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/manufacture/bom/create',
    name: t('Warehousing.ManufactureBOMCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ManufactureBOMCreate),
    permissions: [permission(STAFF_BFF, MANUFACTURE, CREATE_BOM)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/manufacture/bom/:id',
    name: t('Warehousing.ManufactureBOMDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ManufactureBOMDetail),
    permissions: [permission(STAFF_BFF, MANUFACTURE, READ_BOM)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/manufacture/process/:id',
    name: t('Warehousing.ManufactureProcess'),
    flagKey: FLAG_KEYS.EPIC_OMNI_1350,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.ManufactureProcess),
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/manufacture/serial-component',
    name: t('Warehousing.ManufactureSerialComponent'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      WAREHOUSING_PATHS.ManufactureSerialComponent
    ),
    permissions: [permission(STAFF_BFF, MANUFACTURE, READ_SERIAL_CPN)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/action-history/:serviceCode/:actionObject/:actionOn',
    name: t('ActionHistory'),
    permissions: [permission(STAFF_BFF, AUDIT_LOG, READ_WH)],
    component: IFrameAuditLog,
  },
  {
    exact: true,
    path: '/warehousing/file-processing-history/:importType',
    name: t('Warehousing.ImportFileProcessingHistory'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      WAREHOUSING_PATHS.ImportFileProcessingHistory
    ),
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    permissions: [permission(STAFF_BFF, IMPORT_HISTORY, READ)],
  },
  {
    exact: true,
    path:
      '/warehousing/audit-log-action-history/:serviceCode/:actionObject/:actionOn',
    name: t('ActionHistory'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.AuditLogActionHistory),
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/stock-count/sessions',
    name: t('Warehousing.StockCountSessions'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.StockCountSessions),
    permissions: [permission(STAFF_BFF, WAREHOUSE_STOCK_COUNT_SESSION, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    flagKey: FLAG_KEYS.STOCK_COUNT,
  },
  {
    exact: true,
    path: '/warehousing/stock-count/sessions/create',
    name: t('Warehousing.CreateStockCountSession'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.CreateStockCountSession),
    permissions: [permission(STAFF_BFF, WAREHOUSE_STOCK_COUNT_SESSION, CREATE)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    flagKey: FLAG_KEYS.STOCK_COUNT,
    showSiteSwitch: true,
    hideSiteSwitchWhenEnabledFlagKey: FLAG_KEYS.EPIC_OMNI_1409,
    manualTrackingScreenName:
      ManualTrackingScreenName.CREATE_STOCK_COUNT_SESSION,
  },
  {
    exact: true,
    path: '/warehousing/stock-count/sessions/:id',
    name: t('Warehousing.StockCountSessionDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.StockCountSessionDetail),
    permissions: [permission(STAFF_BFF, WAREHOUSE_STOCK_COUNT_SESSION, READ)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    flagKey: FLAG_KEYS.STOCK_COUNT,
  },
  {
    exact: true,
    path: '/warehousing/stock-count/cycles',
    name: t('Warehousing.StockCountCycles'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSposUrl, WAREHOUSING_PATHS.StockCountCycles),
    permissions: [
      permission(STAFF_BFF, WAREHOUSE_STOCK_COUNT_CYCLE, RECORD_WEB),
    ],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    isUseMobileView: true,
    flagKey: FLAG_KEYS.STOCK_COUNT,
    showSiteSwitch: true,
    manualTrackingScreenName: ManualTrackingScreenName.STOCK_COUNT_CYCLES,
  },
  {
    exact: true,
    path: '/warehousing/stock-count/serial-tracking',
    name: t('Warehousing.SerialTracking'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSposUrl, WAREHOUSING_PATHS.SerialTracking),
    permissions: [permission(STAFF_BFF, SERIAL_TRACKING, READ_WEB)],
    hideWhenEnabledFlagKey: EXTENDED_FLAG_KEYS.EPIC_OMNI_1406.key,
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    hideTitle: true,
    isUseMobileView: true,
  },
  {
    exact: true,
    path: '/warehousing/stock-count/serial-tracking',
    name: t('Warehousing.ResearchSerial'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.WMSSerialTracking),
    permissions: [permission(STAFF_BFF, SERIAL_TRACKING, READ_WEB)],
    flagKey: EXTENDED_FLAG_KEYS.EPIC_OMNI_1406.key,
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/warehousing/movement-all-product',
    name: t('Warehousing.MovementAllProduct'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, WAREHOUSING_PATHS.MovementAllProduct),
    permissions: [permission(STAFF_BFF, MOVEMENT_ALL_PRODUCT, CREATE)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    showSiteSwitch: true,
  },
];

export default warehousingRoutes;
